import {
  Box,
  type BoxProps,
  Button,
  type ButtonProps,
  Card,
  type CardProps,
  InlineStack,
  Text,
} from '@shopify/polaris-internal';
import React from 'react';

type TitleType =
  | {
      title: React.ReactNode;
      accessibilityTitle?: never;
    }
  | {
      title?: never;
      accessibilityTitle: string;
    };

type EnhancedCardProps = CardProps &
  TitleType & {
    actions?: ButtonProps[];
    children?: React.ReactNode;
    headerPadding?: BoxProps['padding'];
  };

export function EnhancedCard({
  title,
  accessibilityTitle,
  actions,
  headerPadding = '0',
  children,
  ...rest
}: EnhancedCardProps) {
  const actionsMarkup =
    actions && actions.length > 0 ? (
      <InlineStack gap="600" blockAlign="center">
        {actions.map(({id, children, ...rest}, index) => (
          <Button key={id ?? index} variant="plain" {...rest}>
            {children}
          </Button>
        ))}
      </InlineStack>
    ) : null;

  const hasVisualElement = Boolean(title || actionsMarkup);

  return (
    <Card {...rest}>
      <Box
        {...(hasVisualElement
          ? {
              paddingBlockEnd: '200',
              padding: headerPadding,
            }
          : {
              padding: '0',
            })}
      >
        <InlineStack
          align={title ? 'space-between' : 'end'}
          blockAlign="center"
        >
          <Text visuallyHidden={!title} variant="headingSm" as="h2">
            {title ?? accessibilityTitle}
          </Text>
          {actionsMarkup}
        </InlineStack>
      </Box>
      {children}
    </Card>
  );
}
