import {type LinkAction} from '@shopify/polaris-internal';
import {useI18n} from '@shopify/react-i18n';
import {useContext} from 'react';

import {createI18nConfig} from '~/utils/createI18nConfig';

import {PreviousRouteInfoContext} from './PreviousRouteInfoProvider';
import translations from './translations';

export function usePreviousRouteInfo(fallbackRoute: LinkAction): LinkAction {
  const [i18n] = useI18n(createI18nConfig(translations));
  const previousRouteInfo = useContext(PreviousRouteInfoContext);

  if (!previousRouteInfo) {
    return fallbackRoute;
  }

  // if the urls are the same, the fallbackRoute has better translated "content" property, so use that
  if (previousRouteInfo === fallbackRoute.url) {
    return fallbackRoute;
  }

  return {
    url: previousRouteInfo,
    content: i18n.translate('usePreviousRouteInfo.back'),
  };
}
